export function useBreakpoint(condition, size1, size2) {
	const matchesBreakpoint = ref(false)

	const sizes = {
		sm: 576,
		md: 768,
		lg: 1024,
		xl: 1200,
		xxl: 1440
	}

	const conditions = {
		below: `(max-width: ${sizes[size1] - 1}px)`,
		above: `(min-width: ${sizes[size1]}px)`,
		between: `(min-width: ${sizes[size1]}px) and (max-width: ${sizes[size2] - 1}px)`
	}

	let mql

	function handleMqlChange(e) {
		matchesBreakpoint.value = e.matches
	}

	onMounted(() => {
		mql = window.matchMedia(conditions[condition])
		mql.addEventListener('change', handleMqlChange)
		matchesBreakpoint.value = mql.matches
	})

	onUnmounted(() => {
		mql.removeEventListener('change', handleMqlChange)
	})

	return matchesBreakpoint
}
